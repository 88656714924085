import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7324cfc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-invoices" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "app-administrator-invoices-root" }
const _hoisted_4 = { class: "section-default" }
const _hoisted_5 = { class: "information" }
const _hoisted_6 = { class: "information__box-title" }
const _hoisted_7 = { class: "information__box-link" }
const _hoisted_8 = { class: "information__box-title" }
const _hoisted_9 = { class: "information__box-link" }
const _hoisted_10 = { class: "information__box-title" }
const _hoisted_11 = { class: "information__box-link" }
const _hoisted_12 = { class: "information__box-title" }
const _hoisted_13 = { class: "information__box-link" }
const _hoisted_14 = { class: "information__box-title" }
const _hoisted_15 = { class: "information__box-link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdministratorNavigationVue = _resolveComponent("AdministratorNavigationVue")!
  const _component_Right = _resolveComponent("Right")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AdministratorNavigationVue),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("section", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_row, {
              gutter: 16,
              type: "flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorInvoicesOutgoingInvoicesIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translation.outgoingInvoices), 1),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorInvoicesIncomingInvoicesIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translation.incomingInvoices), 1),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorInvoicesExcerptsRootIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.translation.excerpts), 1),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorInvoicesInvoicePaymentsIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.translation.invoicePayments), 1),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      to: { name: 'AppAdministratorInvoicesCompensationsIndexVue' },
                      class: "information__box"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.translation.compensations), 1),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_Right)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}